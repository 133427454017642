export default {
  colors: {
    textOnDark: "#fff",
    textOnLight: "#333",
    lightBg: "#fff",
    darkBg: "#333",
    primary: "#fff",
    secondary: "#333",
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: {
    none: 0,
    tiny: 4,
    small: 8,
    medium: 16,
    large: 32,
    xl: 64,
    xxl: 128,
    xxxl: 256,
    xxxxl: 512,
  },
  sizes: {
    crossedBatsImage: 16,
    companyImage: 40,
  },
  flex: {
    centerRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    centerCol: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    emojiContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "4px",
      fontWeight: "600",
      letterSpacing: "2px",
      color: "#4f4f4f",
    },
  },
  breakpoints: ["40em", "64em"],
};
